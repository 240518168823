/******************** call functions ********************/

function scrollTop() {
    jQuery('.toTheTop').on('click', function (e) {
        e.preventDefault();

        jQuery("html, body").animate({ scrollTop: 0 }, "slow");
    });

    jQuery('a[href^="#"]').on('click', function (e) {
        e.preventDefault();
        var self = jQuery(this);
        var target = this.hash;
        $target = jQuery(target);
        jQuery('html,body').animate({
            scrollTop: $target.offset().top
        }, 750, function () {
            window.location.hash = target;
        });
    });
}

function printSite() {
    jQuery('.print').on('click', function (e) {
        e.preventDefault();

        window.print();
    });
}

function setMainHeightAsNavHeight() {
    jQuery('main').css('min-height', (jQuery('aside nav').height() + 124));
}

function stickem() {
    jQuery('main').stickem();
}

function paginate(itemsPerPage) {
    var items = jQuery('#listitems'),
        pagination = jQuery('#listitems-pagination'),
        prevBtn = '#listitems-previous',
        nextBtn = '#listitems-next',
        currentPage = 1,
        count = false,
        isWarning = false;

    var totalPages = Math.ceil(items.children().length / itemsPerPage);

    /* Hide all items which come after itemPerPage */
    jQuery(items).children(':gt(' + (itemsPerPage * currentPage - 1) + ')').hide();

    /* If show previous items was clicked */
    pagination.on('click', prevBtn, function () {
        currentPage--;
        jQuery(nextBtn).removeClass('disabled');
        if((currentPage - 1) <= 0){
            jQuery(prevBtn).addClass('disabled');
            currentPage = 1;
        }
        items.children().show();
        items.children(':gt(' + (itemsPerPage * currentPage - 1) + ')').hide();
        items.children(':lt(' + (itemsPerPage * (currentPage - 1)) + ')').hide();
    });

    /* If show next items was clicked */
    pagination.on('click', nextBtn, function () {
        isWarning = false;

        /* Required msg */
        jQuery(items).find('li').each(function( index, li ) {
            if (jQuery(li).is(":visible") && jQuery(li).find('.required').length > 0) {

                count = false;
                jQuery(li).find('input').each(function( index, input ) {
                    if (jQuery(input).is(':checked')) {
                        count = true;
                    }
                });

                if (!count) {
                    jQuery(li).find('.warning').show();
                    isWarning = true;
                } else {
                    jQuery(li).find('.warning').hide();
                }
            }
        });

        if (isWarning) {
            return false;
        }

        currentPage++;
        jQuery(prevBtn).removeClass('disabled');
        if(currentPage >= totalPages){
            jQuery(nextBtn).addClass('disabled');
            currentPage = totalPages;
        }
        items.children().show();
        items.children(':gt(' + (itemsPerPage * currentPage - 1) + ')').hide();
        items.children(':lt(' + (itemsPerPage * (currentPage - 1))  + ')').hide();
    });
}

function playAudioFiles() {
    jQuery('.hearingtest > div').click(function () {
        jQuery('.hearingtest > div').not(this).removeClass('play').find('audio')[0].pause();
        if (jQuery(this).hasClass('play')) {
            jQuery(this).removeClass('play').find('audio')[0].pause();
        } else {
            jQuery(this).addClass('play').find('audio')[0].play();
        }
    });
}

function navigation() {
    var trigger = jQuery('#hamburger');

    trigger.on('click', function() {
        jQuery(this).toggleClass('open');
        jQuery('nav.responsive').toggleClass('open');
        jQuery('main').toggleClass('open');
        jQuery('body').toggleClass('overflow');
    });

    jQuery('main').on('click', function(){
        jQuery('nav.responsive, main, #hamburger').removeClass('open');
        jQuery('body').removeClass('overflow');
    });

    jQuery('.arrow').on('click', function(){
        jQuery(this).next('ul').toggle();
        jQuery(this).children().toggleClass('fa-angle-up');
        jQuery(this).children().toggleClass('fa-angle-down');
    });

    if ( jQuery('.submenu').hasClass('active') ) {
        jQuery('.active .arrow i').removeClass('fa-angle-down');
        jQuery('.active .arrow i').addClass('fa-angle-up');
    }
}

function validateHearingTest() {
    jQuery('#hearingTestForm').on('change', 'input[type="checkbox"]', function() {
        jQuery('#hearingTestForm').find('input[type="checkbox"]').not(this).prop('checked', false);
    });
}

function clone() {
    if (jQuery(window).width() <= 1024 ) {
        jQuery('.shop').clone().insertAfter('p:last').addClass('last');
    }
}

function tinnitusTestForm() {
    var form = '#registerForm';
    jQuery(form).on('change', 'input[name|="form[offer]"]', function () {
        jQuery(form).find('.form-group.address').toggle();
        jQuery(form).find('.form-group.link').toggle();
        jQuery(form).find('.form-group.logo').toggle();
        if (jQuery(this).val() == 0) {
            jQuery(form).find('#address').prop('required', true);
            jQuery(form).find('#link').prop('required', false);
            jQuery(form).find('#logo').prop('required', false);
        }
        if (jQuery(this).val() == 1) {
            jQuery(form).find('#address').prop('required', false);
            jQuery(form).find('#link').prop('required', true);
            jQuery(form).find('#logo').prop('required', true);
        }
    })
}

// Die Ordnung im backend beachten!
function productVariantSelectionCheckboxes() {
    jQuery('.variations .combineCheckboxGroup').on('click', 'input[type="checkbox"]', function () {
        var group = jQuery(this).parent().parent(),
            select = jQuery(group).data('select'),
            values = [],
            combination = "";

        jQuery.each(jQuery(select).find('option'), function(i, option) {
            jQuery(option).prop('selected', false);
        });

        jQuery.each(jQuery(group).find('input[type="checkbox"]'), function(i, checkbox) {
            if (jQuery(checkbox).is(':checked')) {
                values.push(jQuery(checkbox).val());
            }
        });

        jQuery.each(values, function(i, value) {
            if (i > 0) {
                combination += '-' + value;
            } else {
                combination = value;
            }
        });

        jQuery(jQuery(group).data('select')).find('option[value="' + combination + '"]').prop('selected', true);
    });
}

jQuery(document).ready(function() {
    scrollTop();
    printSite();
    setMainHeightAsNavHeight();
    stickem();
    paginate(3);
    playAudioFiles();
    validateHearingTest();
    navigation();
    clone();
    tinnitusTestForm();
    productVariantSelectionCheckboxes();

    jQuery("[data-fancybox]").fancybox({
        animationEffect : false
    });
});
